import React, { FC, ReactChild, ReactChildren, SyntheticEvent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { ButtonWrap, AnchorButton, HashButton } from './button.style';

export interface ButtonProps extends ButtonStyles {
  children: ReactChildren | string | ReactChild;
  onClick?: (e: SyntheticEvent) => void;
  icon?: ReactChild;
  to?: string;
  skin?: string;
  size?: string;
  shape?: string;
  hover?: any;
  varient?: string;
  style?: React.CSSProperties;
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

export interface ButtonStyles {
  fontSize?: string;
  fontWeight?: string | number;
  borderwidth?: string;
  m?: string;
  color?: string;
  pb?: string;
  iconposition?: string;
  bgcolor?: string;
  minwidth?: string;
  fullwidth?: boolean;
  spacer?: any;
}

const Button: FC<ButtonProps> = ({ children, icon, ...props }) => {
  const { to, ...restProps } = props;
  if (to) {
    const internal = /^\/(?!\/)/.test(to);
    const isHash = to.startsWith('#');
    if (internal) {
      return (
        <ButtonWrap as={Link} to={to as string} {...props}>
          {props.iconposition === 'left' && icon && (
            <span className="btn-icon">{icon}</span>
          )}
          {children}
          {props.iconposition === 'right' && icon && (
            <span className="btn-icon">{icon}</span>
          )}
        </ButtonWrap>
      );
    } else if (isHash) {
      return (
        <HashButton href={to} {...restProps}>
          {props.iconposition === 'left' && icon && (
            <span className="btn-icon">{icon}</span>
          )}
          {children}
          {props.iconposition === 'right' && icon && (
            <span className="btn-icon">{icon}</span>
          )}
        </HashButton>
      );
    } else {
      return (
        <AnchorButton
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          {...restProps}
        >
          {props.iconposition === 'left' && icon && (
            <span className="btn-icon">{icon}</span>
          )}
          {children}
          {props.iconposition === 'right' && icon && (
            <span className="btn-icon">{icon}</span>
          )}
        </AnchorButton>
      );
    }
  } else {
    return (
      <ButtonWrap {...props}>
        {props.iconposition === 'left' && icon && (
          <span className="btn-icon">{icon}</span>
        )}
        {children}
        {props.iconposition === 'right' && icon && (
          <span className="btn-icon">{icon}</span>
        )}
      </ButtonWrap>
    );
  }
};

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  fontSize: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  bgcolor: PropTypes.string,
  skin: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
    'gradient',
    'transparent',
  ]),
  size: PropTypes.oneOf(['large', 'medium', 'small', 'xsmall']),
  shape: PropTypes.oneOf(['rectangle', 'rounded', 'bullet']),
  varient: PropTypes.oneOf([
    'outlined',
    'texted',
    'underlined',
    'onlyIcon',
    'contained',
  ]),
  borderwidth: PropTypes.string,
  hover: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  minwidth: PropTypes.string,
  fullwidth: PropTypes.bool,
  iconposition: PropTypes.string,
  spacer: PropTypes.object,
};

Button.defaultProps = {
  fontSize: '15px',
  fontWeight: 500,
  skin: 'primary',
  size: 'medium',
  shape: 'rounded',
  borderwidth: '1px',
  iconposition: 'right',
  hover: 'true',
};

export default Button;
