import React, { FC } from 'react';
import { Link } from 'gatsby';
import Logo1x from 'assets/images/logo/atb-ventures-logo.png';
import Logo2x from 'assets/images/logo/atb-ventures-logo@2x.png';
import Logo3x from 'assets/images/logo/atb-ventures-logo@3x.png';
import WhiteLogo1x from 'assets/images/logo/atb-ventures-logo-white.png';
import WhiteLogo2x from 'assets/images/logo/atb-ventures-logo-white@2x.png';
import WhiteLogo3x from 'assets/images/logo/atb-ventures-logo-white@3x.png';

import { LogoWrapper } from './logo.style';

interface Props {
  variant?: 'primary' | 'white';
}

const Logo: FC<Props> = ({ variant = 'primary' }) => {
  let logo;
  let srcSet;

  switch (variant) {
    case 'primary':
      logo = Logo1x;
      srcSet = `${Logo2x} 2x, ${Logo3x} 3x`;
      break;
    case 'white':
      logo = WhiteLogo1x;
      srcSet = `${WhiteLogo2x} 2x, ${WhiteLogo3x} 3x`;
      break;
    default:
      logo = Logo1x;
      srcSet = `${Logo2x} 2x, ${Logo3x} 3x`;
      break;
  }

  return (
    <LogoWrapper>
      <Link to="/">
        <img
          src={logo}
          srcSet={srcSet}
          className="img-fluid light-logo"
          alt="logo"
        />
      </Link>
    </LogoWrapper>
  );
};

export default Logo;
