import { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const useHeader = ({ onScrollHandler, headerRef, fixedRef }) => {
  const headerData = useStaticQuery(graphql`
    query HeaderQuery {
      allMenuJson {
        edges {
          node {
            id
            text
            link
            # isSubmenu
            # submenu {
            #   link
            #   text
            # }
          }
        }
      }
      site {
        siteMetadata {
          social {
            linkedin
            twitter
          }
        }
      }
    }
  `);
  const menuArr = headerData.allMenuJson.edges;
  const social = headerData.site.siteMetadata.social;
  const [offCanvasOpen, setOffcanvasOpen] = useState(false);
  const [fixedHeaderHeight, setFixedHeaderHeight] = useState(0);
  const [totalHeaderHeight, setTotalHeaderHeight] = useState(0);

  useEffect(() => {
    setFixedHeaderHeight(fixedRef.current.clientHeight);
    setTotalHeaderHeight(headerRef.current.clientHeight);
  }, []);

  const offCanvasHandler = () => {
    setOffcanvasOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const scrollHandler = () => {
      const scrollPos = window.scrollY;
      if (scrollPos > totalHeaderHeight) {
        onScrollHandler({ isDocked: false });
      }

      if (scrollPos < fixedHeaderHeight) {
        onScrollHandler({ isDocked: true });
      }
    };

    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [onScrollHandler, fixedHeaderHeight, totalHeaderHeight]);

  return {
    menuArr,
    social,
    offCanvasHandler,
    offCanvasOpen,
    fixedHeaderHeight,
  };
};

export default useHeader;
