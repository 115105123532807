import React from 'react';
import PropTypes from 'prop-types';
import { BurgerButtonWrap } from './burger-button.style';

interface BurgerButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

const BurgerButton = ({ onClick, className = 'burger-btn', ...props }: BurgerButtonProps) => {
  return (
    <BurgerButtonWrap
      className={className}
      type="button"
      onClick={onClick}
      {...props}
    >
      <span></span>
    </BurgerButtonWrap>
  );
};

BurgerButton.propTypes = {
  onClick: PropTypes.func,
};

export default BurgerButton;
