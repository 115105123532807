import React from 'react';
import { ContainerWrap } from './container.style';

interface ContainerProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

export const Container = ({ children, ...restProps }: ContainerProps) => {
  return <ContainerWrap {...restProps}>{children}</ContainerWrap>;
};
