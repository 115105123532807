import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import Logo from 'components/logo';
import BurgerButton from 'components/ui/burger-button';
import OffCanvas, {
  OffCanvasHeader,
  OffCanvasBody,
} from 'components/ui/off-canvas';
import { MainMenu, MobileMenu } from 'components/menu';
import {
  HeaderWrap,
  HeaderBottom,
  FixedHeader,
  HeaderMain,
  HeaderLeft,
  HeaderMiddle,
  HeaderRight,
  HeaderGroup,
  HeaderNavigation,
  HeaderElement,
  FixedHeaderHeight,
} from './header.style';
import useHeader from './useHeader';
import { TiSocialLinkedin, TiSocialTwitter } from 'react-icons/ti';
import { FC } from 'react';

export interface HeaderProps {
  fixedDisplayVarient?: boolean;
  mainMenuStyle?: any;
  burgerBtnElStyle?: any;
  offCanvasStyle?: any;
  mobileMenuStyle?: any;
}

const Header: FC<HeaderProps> = (props) => {
  const [sticky, setSticky] = useState<boolean>(false);

  const onScrollHandler = ({ isDocked }: { isDocked: boolean }) => setSticky(!isDocked);

  const headerRef = useRef(null);
  const fixedRef = useRef(null);

  const {
    menuArr,
    social,
    offCanvasHandler,
    offCanvasOpen,
    fixedHeaderHeight,
  } = useHeader({
    onScrollHandler,
    headerRef,
    fixedRef,
  });

  const {
    mainMenuStyle,
    burgerBtnElStyle,
    offCanvasStyle,
    mobileMenuStyle,
    fixedDisplayVarient,
  } = props;

  const shouldDisplayFixedVarient = sticky || fixedDisplayVarient;

  return (
    <Fragment>
      <HeaderWrap ref={headerRef} displayVarient={!shouldDisplayFixedVarient}>
        <HeaderBottom>
          <FixedHeader
            ref={fixedRef}
            isSticky={sticky}
            bgColor={!shouldDisplayFixedVarient ? 'transparent' : 'white'}
          >
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <HeaderMain>
                    <HeaderLeft>
                      <Logo
                        variant={
                          !shouldDisplayFixedVarient ? 'white' : 'primary'
                        }
                      />
                    </HeaderLeft>
                    <HeaderMiddle>
                      {shouldDisplayFixedVarient && (
                        <HeaderNavigation>
                          <MainMenu menuData={menuArr} {...mainMenuStyle} />
                        </HeaderNavigation>
                      )}
                    </HeaderMiddle>
                    <HeaderRight>
                      <HeaderElement
                        {...burgerBtnElStyle}
                        visibility={{
                          default: `${
                            !shouldDisplayFixedVarient ? 'true' : 'false'
                          }`,
                          lg: 'true',
                        }}
                      >
                        <BurgerButton
                          onClick={offCanvasHandler}
                          className={
                            !shouldDisplayFixedVarient
                              ? 'homePageBurgerMenuDisplay'
                              : ''
                          }
                        />
                      </HeaderElement>
                      <HeaderGroup>
                        <HeaderElement>
                          <a
                            href={social.twitter}
                            aria-label={'Twitter'}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <TiSocialTwitter />
                          </a>
                        </HeaderElement>
                        <HeaderElement>
                          <a
                            href={social.linkedin}
                            aria-label={'LinkedIn'}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <TiSocialLinkedin />
                          </a>
                        </HeaderElement>
                      </HeaderGroup>
                    </HeaderRight>
                  </HeaderMain>
                </Col>
              </Row>
            </Container>
          </FixedHeader>
          <FixedHeaderHeight
            height={!shouldDisplayFixedVarient ? 0 : fixedHeaderHeight}
          />
        </HeaderBottom>
      </HeaderWrap>
      <OffCanvas
        scrollable={false}
        isOpen={offCanvasOpen}
        onClick={offCanvasHandler}
        {...offCanvasStyle}
      >
        <OffCanvasHeader onClick={offCanvasHandler}>
          <Logo />
        </OffCanvasHeader>
        <OffCanvasBody>
          <MobileMenu menuData={menuArr} mobileMenuStyle={mobileMenuStyle} />
        </OffCanvasBody>
      </OffCanvas>
    </Fragment>
  );
};

Header.propTypes = {
  mainMenuStyle: PropTypes.object,
  burgerBtnElStyle: PropTypes.object,
  offCanvasStyle: PropTypes.object,
  mobileMenuStyle: PropTypes.object,
};

Header.defaultProps = {
  mainMenuStyle: {
    color: '#8c8c8c',
    fontWeight: 600,
  },
  burgerBtnElStyle: {
    pl: '20px',
  },
  offCanvasStyle: {
    offCanvasWrapStyle: {},
    offCanvasInnerStyle: {
      background: '#fff',
    },
  },
  mobileMenuStyle: {
    color: '#333',
    borderBottomColor: '#ededed',
  },
};

export default Header;
