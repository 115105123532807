import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const RowWrap = styled(Row)`
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  padding-top: ${(props) => props.pt};
  padding-bottom: ${(props) => props.pb};
  text-align: ${(props) => props.textalign};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.alignitems};
`;
