import styled from 'styled-components';
import { NavbarWrap } from 'components/ui/navbar/navbar.style';
import { NavItemWrap } from 'components/ui/navbar/navitem/navitem.style';
import {
  NavLinkWrap,
  AnchorTag,
  SmoothScroll,
} from 'components/ui/navbar/navlink/navlink.style';
import { SubmenuWrap } from 'components/ui/navbar/submenu/submenu.style';
import { ExpandButtonWrap } from 'components/ui/navbar/expand-button/expand-button.style';

export const MobileMenuWrap = styled.nav`
  ${NavbarWrap} {
    flex-direction: column;
  }
  ${NavItemWrap} {
    margin-left: 0;
    margin-right: 0;
    position: relative;
    border-bottom: 1px solid
      ${(props) => props.borderBottomColor || 'rgba(255,255,255,0.15)'};
    &:hover {
      & > ${NavLinkWrap}, & > ${AnchorTag}, & > ${SmoothScroll} {
        color: #000;
      }
    }
    ${ExpandButtonWrap} {
      position: absolute;
      right: 0;
      top: 12px;
    }
  }
  .submenu-open {
    & > ${SubmenuWrap} {
      max-height: 1000px;
      visibility: visible;
      opacity: 1;
      padding-top: 12px;
      padding-bottom: 10px;
      pointer-events: visible;
    }
  }
  ${NavLinkWrap},
  ${AnchorTag},
    ${SmoothScroll} {
    display: block;
    color: ${(props) => props.color || '#ffffff'};
    padding: 18px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    span {
      padding: 0;
    }
  }
  ${SubmenuWrap} {
    position: relative;
    min-width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 14px;
    padding-right: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    background-color: transparent;
    top: auto;
    left: 0;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.05);
    border-bottom: none;
    transform: translateY(0);
    max-height: 0;
    overflow-y: hidden;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    transform-origin: top;
    ${NavItemWrap} {
      &:last-child {
        border-bottom: 0;
      }
    }
    ${NavLinkWrap},
    ${AnchorTag},
        ${SmoothScroll} {
      display: block;
      font-size: 15px;
      color: rgba(255, 255, 255, 0.7);
      font-weight: 500;
      line-height: 1.5;
      padding: 10px 0;
    }
    ${ExpandButtonWrap} {
      right: 0;
      top: 6px;
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  }
`;
