import styled, { css } from 'styled-components';
import { device, ResponsiveStyles } from 'theme';
import { fadeInDown } from 'assets/css/animations';
import { BurgerButtonWrap } from '../../../components/ui/burger-button/burger-button.style';

export interface HeaderElementProps {
  pr?: string;
  pl?: string;
  bl?: string;
  responsive?: ResponsiveStyles;
  visibility?: {
    default?: 'true';
    md?: 'true';
    lg?: 'true';
    sm?: 'true';
  }
}


export const HeaderElement = styled.div<HeaderElementProps>`
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: ${(props) => props.pr};
  padding-left: ${(props) => props.pl};
  border-left: ${(props) => props.bl};
  color: ${(props) => props.theme.colors.headingColor};
  a {
    color: ${(props) => props.theme.colors.headingColor};
  }
  a:hover {
    color: ${(props) => props.theme.colors.themeColor};
  }
  ${(props) => props.responsive?.xlarge &&
    css`
      @media ${device.xlarge} {
        padding-right: ${props.responsive.xlarge.pr};
        margin-top: ${props.responsive.xlarge.mt};
      }
    `
}
  ${(props) =>
    props.responsive?.large &&
    css`
      @media ${device.large} {
        padding-right: ${props.responsive.large.pr};
        margin-top: ${props.responsive.large.mt};
      }
  `}

  ${(props) =>
    props.responsive?.medium &&
    css`
      @media ${device.medium} {
        padding-right: ${props.responsive.medium.pr};
        margin-top: ${props.responsive.medium.mt};
      }
  `}

  ${(props) =>
    props.responsive?.small &&
    css`
      @media ${device.small} {
        padding-right: ${props.responsive.small.pr};
        margin-top: ${props.responsive.small.mt};
      }
    `}

  ${(props) =>
    props.responsive?.xsmall &&
    css`
      @media ${device.xsmall} {
        padding-right: ${props.responsive.xsmall.pr};
        margin-top: ${props.responsive.xsmall.mt};
      }
  `}
    
  ${(props) =>
    props.visibility &&
    css`
      display: ${props.visibility.default === 'true' ? 'flex' : 'none'};
      @media ${device.large} {
        display: ${(props.visibility.lg === 'true' ? 'flex' : 'none')};
      }
      @media ${device.medium} {
        ${props.visibility.md !== undefined &&
      css`
            display: ${props.visibility.md === 'true' ? 'flex' : 'none'};
          `}
      }
      @media ${device.small} {
        ${props.visibility.sm !== undefined &&
      css`
            display: ${props.visibility.sm === 'true' ? 'flex' : 'none'};
          `}
      }
    `}
`;

export const HeaderWrap = styled.header<{ displayVarient: boolean }>`
  border-color: #e4e8f6;
  position: relative;
  ${(props) =>
    props.displayVarient &&
    css`
      ${BurgerButtonWrap} span {
        @media ${device.small} {
          background-color: ${(props) => props.theme.colors.white};
      
          &:before {
            background-color: ${(props) => props.theme.colors.white};
          }
          &:after {
            background-color: ${(props) => props.theme.colors.white};
          }
        }
      }
      ${BurgerButtonWrap}:hover {
        & span {
          background-color: ${(props) => props.theme.colors.themeColor};
          &:before {
            width: 80%;
            background-color: ${(props) => props.theme.colors.themeColor};
          }
          &:after {
            width: 60%;
            background-color: ${(props) => props.theme.colors.themeColor};
          }
        }
      }
    }
    ${HeaderElement} a {
      color: ${(props) => props.theme.colors.black};
    }
    @media ${device.small} {
      ${HeaderElement} a {
        color: ${(props) => props.theme.colors.white};
      }
    }
  `}
`;

export const HeaderTop = styled.div< { borderBottom: boolean }>`
  ${(props) =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
    `}
`;

export const HeaderBottom = styled.div`
  position: relative;
`;

export const FixedHeader = styled.div<{ isSticky: boolean, bgColor?: string }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: #fff;
  z-index: 1;
  ${(props) =>
    props.isSticky &&
    css`
      position: fixed;
      z-index: 999;
      box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
      animation: 0.95s ease-in-out 0s normal none 1 running ${fadeInDown};
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    `}
  ${(props) =>
    props.bgColor &&
    css`
      background-color: ${props.bgColor};
    `}
`;

export const HeaderMain = styled.div`
  display: flex;
  position: relative;
`;

export const HeaderLeft = styled.div`
  flex-basis: auto;
  justify-content: center;
  padding: 20px 25px;
  @media ${device.xxlarge} {
    flex-basis: auto;
  }
  @media ${device.xlarge} {
    flex-basis: auto;
  }
  @media ${device.large} {
    flex-basis: auto;
    justify-content: flex-start;
  }
  @media ${device.xsmall} {
    flex-basis: auto;
  }
`;

export const HeaderMiddle = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const HeaderRight = styled.div`
  flex-shrink: 0;
  flex-basis: 15%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media ${device.large} {
    flex-basis: 60%;
  }
  @media ${device.xsmall} {
    flex-basis: 50%;
  }
`;

export const HeaderGroup = styled.div`
  margin: 0 25px;
  display: flex;
  align-items: center;
  & > * {
    padding: 5px;
  }
`;

export const HeaderNavigation = styled.div`
  display: block;
  @media ${device.large} {
    display: none;
  }
`;
export const HeaderRightInner = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  @media ${device.small} {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    padding: 20px;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    visibility: hidden;
    opacity: 0;
    transform: translateY(30px);
    z-index: 9;
    height: auto;
    ${(props) =>
    props.isOpen &&
      css`
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
      `}
  }
  @media ${device.xsmall} {
    flex-direction: column;
  }
`;


export const FixedHeaderHeight = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
`;
