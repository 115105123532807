import React from 'react';
import { OffCanvasHeader } from './off-canvas-header';
import { OffCanvasBody } from './off-canvas-body';
import { OffCanvaseWrap, OffCanvasInner } from './off-canvas.style';

const OffCanvas = ({
  children,
  onClick,
  offCanvasWrapStyle,
  offCanvasInnerStyle,
  ...props
}) => {
  return (
    <OffCanvaseWrap {...offCanvasWrapStyle} {...props} onClick={onClick}>
      <OffCanvasInner
        {...offCanvasInnerStyle}
        {...props}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </OffCanvasInner>
    </OffCanvaseWrap>
  );
};

export { OffCanvasHeader, OffCanvasBody };
export default OffCanvas;
