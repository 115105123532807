import styled from 'styled-components';

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  a {
    img {
      width: 195px;
    }
  }
`;
