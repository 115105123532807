import React from 'react';
import { RowWrap } from './row.style';

interface RowProps {
  children: object;
  noGutter?: boolean;
  className?: string;
  alignitems?: string;
}

export const Row = ({ children, noGutter, ...restProps }: RowProps) => {
  const classes = [];
  if (noGutter) {
    classes.push('no-gutters');
  }
  return (
    <RowWrap className={classes.join(' ')} {...restProps}>
      {children}
    </RowWrap>
  );
};
