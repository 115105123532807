import React from 'react';
import { ColWrap } from './col.style';

interface ColProps {
  children: JSX.Element | JSX.Element[];
  xl?: number | object;
  lg?: number | object;
  md?: number | object;
  sm?: number | object;
  xs?: number | object;
  className?: string;
  align?: string;
}

export const Col = ({ children, ...restProps }: ColProps) => {
  return <ColWrap {...restProps}>{children}</ColWrap>;
};
