import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { HeadingWrapper } from './heading.style';
import { ResponsiveStyles, StyledComponentsBaseStylingProps } from 'theme';

export interface HeadingStyles extends StyledComponentsBaseStylingProps {
  responsive?: ResponsiveStyles;
  child?: {
    color?: string;
  }
}

const Heading: FC<{ children: React.ReactElement | string } & HeadingStyles > = ({ children, ...props }) => {
  return <HeadingWrapper {...props}>{children}</HeadingWrapper>;
};

Heading.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  fontSize: PropTypes.string,
  color: PropTypes.string,
};

Heading.defaultProps = {
  as: 'h2',
};

export default Heading;
